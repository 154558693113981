export const DOMAIN_ID = 'demo';

export const USER_TYPE = {
    SUPER_ADMIN: 'SUPER_ADMIN',
    ADMIN: 'ADMIN',
    USER: 'USER',
}

export const SESSION_STATUS = {
    AVAILABLE: 'AVAILABLE',
    OCCUPIED: 'OCCUPIED',
    EXCLUDED: 'EXCLUDED',
    EXPIRED: 'EXPIRED',
    PENDING_APPROVAL: 'PENDING_APPROVAL',
}

export const BOOKING_RECORD_STATUS = {
    PENDING: 'PENDING',
    CONFIRMED: 'CONFIRMED',
    CANCELLED_BY_USER: 'CANCELLED_BY_USER',
    CANCELLED_BY_ADMIN: 'CANCELLED_BY_ADMIN',
    EXPIRED: 'EXPIRED',
    NO_SHOW: 'NO_SHOW',
}

export const BOOKING_STATUS = {
    RESERVED: 'RESERVED',
    PENDING_APPROVAL: 'PENDING_APPROVAL',
    REJECTED: 'REJECTED',
    CONFIRMED: 'CONFIRMED',
    CREATED_BY_ADMIN: 'CREATED_BY_ADMIN',
    CANCELLED_BY_USER: 'CANCELLED_BY_USER',
    REFUNDED: 'REFUNDED',
    CANCELLED_BY_ADMIN: 'CANCELLED_BY_ADMIN',
    EXPIRED: 'EXPIRED',
    NO_SHOW: 'NO_SHOW',
}

export const INVITATION_ROLE = {
    CHAIR: 'CHAIR',
    REQUIRED_PARTICIPANT: 'REQUIRED_PARTICIPANT',
    OPTIONAL_PARTICIPANT: 'OPTIONAL_PARTICIPANT',
    // NON_PARTICIPANT: 'NON-PARTICIPANT',
}

export const INVITATION_STATUS = {
    NEEDS_ACTION: 'NEEDS-ACTION',
    ACCEPTED: 'ACCEPTED',
    DECLINED: 'DECLINED',
    TENTATIVE: 'TENTATIVE',
    CANCELLED: 'CANCELLED',
}

export const ITEM_TYPE_ATTRIBUTE_TYPE = {
    TEXT: 'TEXT',
    NUMBER: 'NUMBER',
    NUMBER_MIN: 'NUMBER_MIN',
    NUMBER_MAX: 'NUMBER_MAX',
    RANGE: 'RANGE',
    DATE: 'DATE',
    DATE_RANGE: 'DATE_RANGE',
    BOOLEAN: 'BOOLEAN',
}

export const ITEM_TYPE_ADDITIONAL_ITEM_TYPE = {
    TEXT: 'TEXT',
    NUMBER: 'NUMBER',
    DATE: 'DATE',
    TIME: 'TIME',
    BOOLEAN: 'BOOLEAN',
    OPTIONS: 'OPTIONS',
}


export const JWT_KEY = {
    ACCOUNT_ID: 'sub',
    ISSUED_AT: 'iat',
    EXPIRED_AT: 'exp',
    INTENTION: 'int',
    DEVICE_ID: 'dvc',
    BOOKING_ID: 'bok',
    ITEM_ID: 'itm',
    SESSION_ID: 'ses',
    BOOKING_RECORD_ID: 'bkr',
    CHECKIN_PIN: 'pin',
}

export const CHECKIN_MODE = {
    APP_ONE_CLICK: 'APP_ONE_CLICK',
    KIOSK_ONE_CLICK: 'KIOSK_ONE_CLICK',
    APP_KIOSK: 'APP_KIOSK',
    KIOSK_PIN: 'KIOSK_PIN',
}

export const OPTIONAL_FEATURES = {
    BOOKING_HOSTS: 'hosts',
    BOOKING_TITLE: 'title',
    BOOKING_INVITATIONS: 'invitations',
    ASD_EXPORT_CAR_BOOKING: 'asd_export_car_booking',
}

export const ITEM_TYPE_LAYOUT_TYPES = {
    DISPLAY: 'DISPLAY',
    ACCOUNT_DISPLAY: 'ACCOUNT_DISPLAY',
    DATE_DISPLAY: 'DATE_DISPLAY',
    TIME_DISPLAY: 'TIME_DISPLAY',
    DATETIME_DISPLAY: 'DATETIME_DISPLAY',
    TEXT: 'TEXT',
    RECURRENCE: 'RECURRENCE',
    ACCOUNTS: 'ACCOUNTS',
    RSVP: 'RSVP',
    BOOKING_STATUS: 'BOOKING_STATUS',
    BOOKING_DATETIME: 'BOOKING_DATETIME',

    CUSTOM_ATTRIBUTE: 'CUSTOM_ATTRIBUTE',
    ADDITIONAL_ITEM: 'ADDITIONAL_ITEM',
}

export const ITEM_TYPE_LAYOUT_KEYS = {
    ITEM_TYPE_NAME: 'ITEM_TYPE_NAME',
    ITEM_NAME: 'ITEM_NAME',
    BOOKING_STATUS: 'BOOKING_STATUS',
    BOOKING_TITLE: 'BOOKING_TITLE',
    BOOKING_DATETIME: 'BOOKING_DATETIME',
    BOOKING_DATE: 'BOOKING_DATE',
    BOOKING_TIME: 'BOOKING_TIME',
    BOOKING_START_TIME: 'BOOKING_START_TIME',
    BOOKING_END_TIME: 'BOOKING_END_TIME',
    BOOKING_CREATE_DATE: 'BOOKING_CREATE_DATE',
    BOOKING_APPROVAL_DATE: 'BOOKING_APPROVAL_DATE',
    BOOKING_APPROVAL_BY: 'BOOKING_APPROVAL_BY',
    BOOKING_CREATOR: 'BOOKING_CREATOR',
    BOOKING_RECURRENCE: 'BOOKING_RECURRENCE',
    BOOKING_HOSTS: 'BOOKING_HOSTS',
    BOOKING_RSVP: 'BOOKING_RSVP',
}

export const DEFAULT_DATETIME_FORMAT = ''
