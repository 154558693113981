<template>
  <v-container fluid class="schedule-container">
    <v-row no-gutters
           class="flex-grow-0 align-center">
      <v-btn tile text small
             @click="today">Today
      </v-btn>
      <v-btn tile text small
             @click="prev">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn tile text small
             @click="next">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      {{ calendarDate }}
      <v-spacer></v-spacer>
      <v-btn-toggle tile
                    dense
                    group
                    mandatory
                    color="primary"
                    v-model="calendarType">
        <v-btn :value="'day'">
          D
        </v-btn>
        <v-btn :value="'4day'">
          4
        </v-btn>
        <v-btn :value="'month'">
          M
        </v-btn>
      </v-btn-toggle>
    </v-row>
    <v-sheet height="calc(100vh - 142px)"
             :class="['flex-grow-1', {'app-bar-shown-md-and-up': appBarShown && $vuetify.breakpoint.mdAndUp}, {'app-bar-shown-sm-and-down': appBarShown && $vuetify.breakpoint.smAndDown}]">
      <v-calendar ref="calendar"
                  :type="calendarType"
                  :weekdays="[1,2,3,4,5,6,0]"
                  :start="calendarStart"
                  :end="calendarEnd"
                  :events="events"
                  :event-color="getEventColor"
                  v-model="selectedDate"
                  @click:more="calendarClickDate"
                  @click:date="calendarClickDate"
                  event-overlap-mode="stack">
        <template v-slot:event="{event}">
          <v-container fluid
                       :class="['font-weight-bold', 'fill-height', 'py-0', 'pl-1', event.status,
              {'pending-approval': event.status === BOOKING_STATUS.PENDING_APPROVAL}]"
                       @click="onEventClick($event, event)">
            {{ eventLabel(event) }}
          </v-container>
        </template>
      </v-calendar>
    </v-sheet>

    <!--BOOKING DETAIL-->
    <BookingDetailModal :shown="bookingDetailModalShown"
                        @dismiss="onBookingDetailModalDismiss"
                        :booking="showingBooking"
                        show-rsvp-actions></BookingDetailModal>

    <!--FILTER-->
    <v-navigation-drawer color="#F4F5F7"
                         v-model="filterShown"
                         app
                         right
                         disable-resize-watcher
                         @transitionend="$refs.filterForm.validate()">
      <v-container class="filter-container">
        <v-row class="flex-grow-0">
          <v-col>FILTER</v-col>
        </v-row>
        <v-form ref="filterForm">
          <v-date-picker :value="selectedDateString"
                         @input="onDateSelected"
                         hide-details="auto"
                         dense
                         no-title
                         scrollable
                         full-width></v-date-picker>

          <v-autocomplete outlined
                          label="Facility"
                          v-model="selectedItems"
                          :rules="[value => !!value || 'Required']"
                          :items="itemOptions"
                          class="flex-grow-0"
                          @change="load"></v-autocomplete>
        </v-form>
        <!--        <v-select label="My Role"-->
        <!--                  v-model="roles"-->
        <!--                  :items="roleOptions"-->
        <!--                  :return-object="false"-->
        <!--                  multiple-->
        <!--                  chips-->
        <!--                  deletable-chips-->
        <!--                  class="flex-grow-0"></v-select>-->

        <v-spacer></v-spacer>
        <!--        <v-row class="flex-grow-0 font-weight-bold text-center">-->
        <!--          <v-col>LEGENDS</v-col>-->
        <!--        </v-row>-->
        <v-list dense>
          <v-subheader>Booking Status</v-subheader>
          <v-list-item v-for="status in bookingStatus">
            <v-list-item-icon>
              <v-avatar
                  :color="status"
                  rounded
                  size="20"></v-avatar>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t(`booking_status.${status}`) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list dense>
          <v-subheader>Holiday</v-subheader>
          <v-list-item>
            <v-list-item-icon>
              <v-avatar
                  color="HOLIDAY"
                  rounded
                  size="20"></v-avatar>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>Public Holiday</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-navigation-drawer>

    <!--FILTER BUTTON-->
    <v-fab-transition>
      <v-btn color="primary"
             fab
             bottom
             right
             v-if="!filterShown"
             @click="filterShown = true"
             class="filter-btn">
        <v-icon>
          fas fa-filter
        </v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
import {computed, onMounted, ref, watch} from '@vue/composition-api';
import {DateTime} from 'luxon';
import {ACTION_TYPES} from "@/store/types";
import {BOOKING_STATUS, INVITATION_STATUS} from "@/constants";
import {Item, ItemType} from "@/store/models";
import _ from 'lodash';
import store from "@/store";
import BookingDetailModal from "@/components/BookingDetailModal";

export default {
  name: 'ItemSchedule',
  components: {BookingDetailModal},
  setup(props, {root}) {
    const appBarShown = computed(() => root.$store.getters.appBarShown);

    const selectedDate = ref(new Date());
    const selectedDateString = computed(() => {
      return DateTime.fromJSDate(selectedDate.value).toFormat('yyyy-MM-dd');
    });
    const onDateSelected = function (value) {
      selectedDate.value = DateTime.fromFormat(value, 'yyyy-MM-dd').toJSDate();
    };
    watch(() => selectedDate.value, (newValue, oldValue) => {
      if (newValue) {
        load();
      }
    });
    const today = function () {
      selectedDate.value = new Date();
    };
    const prev = function () {
      selectedDate.value = DateTime.fromJSDate(selectedDate.value).minus({
        'month': {'months': 1},
        '4day': {'days': 4},
        'day': {'days': 1},
      }[calendarType.value]).startOf('day').toJSDate();
    };
    const next = function () {
      selectedDate.value = DateTime.fromJSDate(selectedDate.value).plus({
        'month': {'months': 1},
        '4day': {'days': 4},
        'day': {'days': 1},
      }[calendarType.value]).startOf('day').toJSDate();
    };

    const bookings = ref([]);
    const publicHolidays = ref([]);

    // Filter
    const selectedItem = ref(null);
    const selectedItems = ref(null);
    const itemOptions = computed(() => {
      // let options = [];
      let itemsByName = {};

      ItemType.query().where((i) => !i.deleted).orderBy('ordering').get().forEach((itemType) => {
        Item.query().where('type_id', itemType.id).orderBy('name').get().map((item)=> {
          if (!itemsByName[item.name]) {
            itemsByName[item.name] = [];
          }
          itemsByName[item.name].push(item);
        })

        // options = _.concat(options, [
        //   {header: itemType.name},
        //   ...Item.query().where('type_id', itemType.id).orderBy('name').get().map((item) => {
        //     return {text: item.name, value: item}
        //   })
        // ])
      });

      return _.map(itemsByName, (items, name)=> {
        return {text: name, value: items};
      })

      // return options;
    })

    // Legends
    const bookingStatus = ref([
      BOOKING_STATUS.CONFIRMED,
      BOOKING_STATUS.PENDING_APPROVAL,
      BOOKING_STATUS.CANCELLED_BY_USER,
    ])

    const events = computed(() => {
      return [
        ...bookings.value.map((b) => {

          return {
            ...b,
            name: b.title || b.item.name,
            start: DateTime.fromISO(b.start_time).toFormat('yyyy-MM-dd HH:mm'),
            end: DateTime.fromISO(b.end_time).toFormat('yyyy-MM-dd HH:mm'),
          }
        }),
        ...publicHolidays.value.map((ph) => {
          return {
            name: ph.name,
            title: ph.name,
            start: DateTime.fromISO(ph.date).startOf('day').toJSDate(),
            end: DateTime.fromISO(ph.date).endOf('day').toJSDate(),
            timed: false,
          }
        })
      ]
    })
    const eventLabel = function (event) {
      if (event.timed === false) {
        return event.title
      }

      let title = event.title;
      let hosts = event.hosts.map((h) => h.name).join(", ")

      if (!title && !hosts) {
        title = event.item.name;
      }

      return `${title || ''} ${title && hosts ? "-" : ''} ${hosts}`
    };
    const getEventColor = function (event) {
      return event.timed === false ? 'red' : 'primary';
    }
    const onEventClick = function (evt, booking) {
      if (booking.timed === false) return;

      showingBooking.value = {
        ...booking,
        hosts: _.orderBy(booking.hosts, [(h) => h.id === booking.account.id ? -1 : 0, 'name'], ['asc', 'asc'])
      };
      bookingDetailModalShown.value = true;
    }

    const showingBooking = ref(null);
    const bookingDetailModalShown = ref(false);
    const onBookingDetailModalDismiss = function (needRefresh) {
      bookingDetailModalShown.value = false;
      console.log(needRefresh);
      if (needRefresh) {
        load();
      }
    }

    const calendarType = ref('month');
    watch(() => calendarType.value, () => {
      load();
    })
    const calendarTypes = ref([
      {text: 'Day', value: 'day'},
      {text: 'Week', value: 'week'},
      {text: 'Month', value: 'month'},
    ])
    const calendarStart = computed(() => {
      let d = DateTime.fromJSDate(selectedDate.value);

      return {
        'day': d.startOf('day').toJSDate(),
        '4day': d.startOf('day').toJSDate(),
        'week': d.startOf('week').toJSDate(),
        'month': d.startOf('month').toJSDate(),
      }[calendarType.value];
    })
    const calendarEnd = computed(() => {
      let d = DateTime.fromJSDate(selectedDate.value);

      return {
        'day': d.startOf('day').toJSDate(),
        '4day': d.startOf('day').plus({'days': 4}).toJSDate(),
        'week': d.endOf('week').toJSDate(),
        'month': d.endOf('month').toJSDate(),
      }[calendarType.value];
    })
    const calendarClickDate = function ({date}) {
      selectedDate.value = DateTime.fromISO(date).toJSDate();
      calendarType.value = {
        'month': 'day',
        '4day': 'day',
        'day': 'day',
      }[calendarType.value];
    };
    const calendarDate = computed(() => {
      if (calendarType.value === '4day') {
        // Check if across months
        let start = DateTime.fromJSDate(calendarStart.value)
        let end = DateTime.fromJSDate(calendarEnd.value)
        if (!start.hasSame(end, 'month')) {
          if (start.hasSame(end, 'year')) {
            return `${start.toFormat('LLLL')} - ${end.toFormat('LLLL yyyy')}`
          } else {
            return `${start.toFormat('LLLL  yyyy')} - ${end.toFormat('LLLL yyyy')}`
          }
        }
      }

      return {
        'day': DateTime.fromJSDate(calendarStart.value).toFormat('LLLL d, yyyy'),
        '4day': DateTime.fromJSDate(calendarStart.value).toFormat('LLLL yyyy'),
        'month': DateTime.fromJSDate(calendarStart.value).toFormat('LLLL yyyy'),
      } [calendarType.value]
    })

    const filterShown = ref(true);
    // watch(() => filterShown.value, (newValue) => {
    //   if (!newValue && !selectedItem.value) {
    //     setTimeout(() => {
    //       filterShown.value = true
    //     }, 100)
    //   }
    // })

    const datePickerAttributes = computed(() => {
      let attrs = [
        {
          key: 'today',
          highlight: {
            color: 'red',
            fillMode: 'light',
          }, dates: new Date(),
        },
      ];

      if (calendarType.value === 'week') {
        attrs.push({
          key: 'selected-week',
          highlight: {
            color: 'red',
            fillMode: 'light',
          },
          dates: {
            start: calendarStart.value,
            end: calendarEnd.value,
          },
        })
      }

      return attrs;
    })

    const load = async function () {
      // if (!selectedItem.value) return;
      if (!selectedItems.value) return;

      store.dispatch(ACTION_TYPES.SET_APP_BAR_TITLE, selectedItems.value[0].name)

      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'client/item_schedule/',
        params: {
          // item_id: selectedItem.value.id,
          item_ids: selectedItems.value.map((i)=> i.id),
          start_date: DateTime.fromJSDate(calendarStart.value).toFormat('yyyy-MM-dd'),
          end_date: DateTime.fromJSDate(calendarEnd.value).startOf('day').plus({days: 1}).toFormat('yyyy-MM-dd'),
        }
      });

      let bookingRecordMap = {};
      response.body.booking_records.forEach((r) => {
        bookingRecordMap[r.id] = r;
      })

      bookings.value = response.body.bookings.map((b) => {
        return {
          ...b,
          bookingRecord: bookingRecordMap[b.booking_record],
        }
      });
      publicHolidays.value = response.body.holidays;

      filterShown.value = root.$vuetify.breakpoint.lgAndUp;
    };

    watch(() => root.$store.getters.account, (newValue) => {
      if (newValue) {
        load();
      }
    }, {immediate: true})

    watch(() => root.$route.params.itemId, (newValue) => {
      if (newValue) {
        selectedItem.value = Item.query().find(newValue);
        selectedItems.value = Item.query().where('id', newValue).get();
        load();
      }
    }, {immediate: true})

    return {
      appBarShown,

      selectedDate,
      selectedDateString,
      onDateSelected,
      today,
      prev,
      next,

      selectedItem,
      selectedItems,
      itemOptions,

      bookingStatus,

      events,
      eventLabel,
      getEventColor,
      onEventClick,

      showingBooking,
      bookingDetailModalShown,
      onBookingDetailModalDismiss,

      calendarType,
      calendarTypes,
      calendarStart,
      calendarEnd,
      calendarClickDate,
      calendarDate,

      filterShown,

      datePickerAttributes,

      load,

      BOOKING_STATUS,
      INVITATION_STATUS,
    }
  },
}
</script>

<style lang="less">
.item-schedule-container {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  .filter-container {
    height: 100% !important;
    display: flex;
    flex-direction: column;

    .vc-container {
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }

  .v-calendar .v-event-timed-container {
    margin-right: 0 !important;
  }

  .filter-btn {
    position: fixed;
    right: 24px;
    bottom: 24px;
    z-index: 10;
    transition: bottom 0.5s ease-out;
  }

  .PENDING_APPROVAL {
    background: repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 10px, rgba(255, 255, 255, 0.5) 10px, rgba(255, 255, 255, 0.5) 20px)
  }

  .REJECTED, .CANCELLED_BY_USER, .CANCELLED_BY_ADMIN {
    text-decoration: line-through;
    background: rgba(255, 255, 255, 0.5)
  }

  .role_invitee {
    &.RSVP_NEEDS-ACTION {
      color: var(--v-primary-base);
      border: 2px solid var(--v-primary-base);
    }

    &.RSVP_ACCEPTED {
      background-color: var(--v-primary-base);
    }

    &.RSVP_DECLINED {
      color: var(--v-primary-base);
      border: 2px solid var(--v-primary-base);
      text-decoration: line-through;
    }

    &.RSVP_TENTATIVE {
      color: var(--v-primary-darken1);
      border: 2px solid var(--v-primary-base);
      background: repeating-linear-gradient(45deg, var(--v-primary-lighten2), var(--v-primary-lighten2) 10px, var(--v-primary-darken1) 10px, var(--v-primary-darken1) 12px);
    }
  }
}
</style>
