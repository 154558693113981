<template>
  <v-container fluid :class="['remote-paginated-list', 'pa-0', {'full-height': fullHeight}]">
    <v-row no-gutters
           v-if="!hideSearch"
           class="remote-paginated-list--filter-row">
      <v-col>
        <AdvancedSearchInput v-model="search"
                             :filter-options="filterOptions"
                             :exclude-filter-fields="excludedFilterFields"
                             @filter="onFilterChange"></AdvancedSearchInput>
      </v-col>
    </v-row>
    <v-data-table :loading="isLoading"
                  :items="items"
                  :server-items-length="itemCount"
                  :search="search"
                  :options.sync="tableOptions"
                  @update:options="onTableOptionUpdated"
                  :footer-props="{itemsPerPageOptions: [10, 20, 50]}"
                  :fixed-header="fullHeight"
                  class="remote-paginated-list--table"
                  :single-select="singleSelect"
                  v-bind="$attrs"
                  v-on="$listeners">
      <template #header.data-table-select v-if="!singleSelect">
        <v-simple-checkbox :value="selectedItemIds.length === itemCount"
                           @input="selectedAllToggled"
                           :ripple="false"></v-simple-checkbox>
      </template>
      <template #item.data-table-select="{item}" v-if="!singleSelect">
        <v-simple-checkbox :value="selectedItemIds.indexOf(item.id) !== -1"
                           @input="itemSelected(item)"
                           :key="item.id"
                           :ripple="false"></v-simple-checkbox>
      </template>
      <template v-for="key in tableTemplateKeys"
                v-slot:[key]="context">
        <slot :name="key" v-bind="context"></slot>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import AdvancedSearchInput from "@/components/AdvancedSearchInput";
import {computed, ref, watch} from "@vue/composition-api";
import _ from "lodash";
import {ACTION_TYPES} from "@/store/types";

export default {
  name: 'RemotePaginatedList',
  components: {AdvancedSearchInput},
  props: {
    fullHeight: Boolean,
    hideSearch: Boolean,
    filterOptions: Array,
    excludedFilterFields: Array,
    value: Array,
    dataUrl: String,
    dataKey: String,
    extraParams: Object,
    extraFilters: Object,
    refreshTrigger: Boolean,
    multiSort: Boolean,
    keepSelectionOnFilter: Boolean,
    singleSelect: Boolean,
    itemsPerPage: {
      type: Number,
      default: 10,
    },
  },
  setup(props, {root, emit, slots}) {
    const search = ref('');

    // Filter
    const filters = ref({});
    const onFilterChange = function (value) {
      filters.value = value;
      if (!props.keepSelectionOnFilter) {
        selectedItemIds.value = [];
      }
      tableOptions.value = {
        ...tableOptions.value,
        page: 1,
      }
      getItem();
    };

    // Selection
    const selectedItemIds = ref([]);
    watch(() => selectedItemIds.value, (newValue, oldValue) => {
      if (_.xor(newValue, oldValue).length) {
        emit('input', newValue);
      }
    });
    const itemSelected = function (item) {
      let idx = selectedItemIds.value.indexOf(item.id);

      if (idx === -1) {
        selectedItemIds.value = _.union(selectedItemIds.value, [item.id]);
      } else {
        let array = selectedItemIds.value;
        // _.remove(array, (i) => {
        //   return i === item.id;
        // });
        array.splice(idx, 1)
        selectedItemIds.value = array;
      }
    };
    const selectedAllToggled = async function () {
      if (selectedItemIds.value.length !== itemCount.value) {
        if (await root.$dialog.confirm({text: `Select All ${itemCount.value} Items?`})) {
          let params = {
            ...props.extraParams,

            filters: {
              ...filters.value,
              ...props.extraFilters,
            },
            page_no: tableOptions.value.page,
            page_size: tableOptions.value.itemsPerPage,
            sort_by: tableOptions.value.sortBy.map((key, idx) => {
              return tableOptions.value.sortDesc[idx] ? '-' + key : key;
            })
          };

          let response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
            url: props.dataUrl + 'id/',
            params,
          });

          selectedItemIds.value = response.body.ids;
        }
      } else {
        selectedItemIds.value = [];
      }
    };

    // Table
    const tableOptions = ref({
      page: 1,
      itemsPerPage: props.itemsPerPage,
      multiSort: props.multiSort,
      sortBy: [],
      sortDesc: [],
    });
    const onTableOptionUpdated = function (options) {
      tableOptions.value = options;
      getItem();
    };

    const tableTemplateKeys = computed(() => {
      return Object.keys(slots);

      // let headerValues = props.headers.map((h) => h.value);
      //
      // return _.filter(Object.keys(slots), (key) => headerValues.indexOf(key.substr(5)) !== -1);
    });

    // Item
    const isLoading = ref(false);
    const items = ref([]);
    const itemCount = ref(0);
    const getItem = async function () {
      let params = {
        ...props.extraParams,

        filters: {
          ...filters.value,
          ...props.extraFilters,
        },
        page_no: tableOptions.value.page,
        page_size: tableOptions.value.itemsPerPage,
        sort_by: tableOptions.value.sortBy.map((key, idx) => {
          return tableOptions.value.sortDesc[idx] ? '-' + key : key;
        })
      };

      let response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: props.dataUrl,
        params,
      });

      items.value = response.body[props.dataKey];
      itemCount.value = response.body.count;
    };


    watch(() => props.refreshTrigger, (newValue) => {
      if (newValue) {
        getItem();
        emit('update:refresh-trigger', false);
      }
    }, {immediate: true})

    watch(() => props.value, (newValue, oldValue) => {
      if (_.xor(newValue, oldValue).length) {
        selectedItemIds.value = newValue;
      }
    })

    watch(() => props.extraFilters, (newValue, oldValue) => {
      if (newValue && !_.isEqual(newValue, oldValue)) {
        getItem();
      }
    }, {deep: true});

    watch(() => props.extraParams, (newValue, oldValue) => {
      if (newValue && !_.isEqual(newValue, oldValue)) {
        getItem();
      }
    }, {deep: true});

    return {
      search,

      onFilterChange,

      selectedItemIds,
      itemSelected,
      selectedAllToggled,

      tableOptions,
      onTableOptionUpdated,

      tableTemplateKeys,

      isLoading,
      items,
      itemCount,
    }
  },
}
</script>

<style lang="less">
.remote-paginated-list {
  display: flex;
  flex-direction: column;

  &.full-height {
    height: 100%;

    .remote-paginated-list--table {
      flex: 1 1 0;
      display: flex;
      flex-direction: column;

      .v-data-table__wrapper {
        flex: 1 1 0;
      }

      .v-data-footer {
        margin-right: 0 !important;
      }
    }
  }

  .remote-paginated-list--filter-row {
    flex: 0 0 auto;
  }

  .v-data-footer {
    font-size: 0.7rem;
  }
}
</style>
